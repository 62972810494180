import React from "react"

import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
// import Newsletter from "../components/Newsletter"

const BlogPage = ({ data }) => {
  return (
    <Layout>
      <SiteMetadata title="Blog" description="Blog of Kranthi Lakum" />
      <div className="bg-gray-100 py-12 lg:py-16">
        <div className="container bg-white py-12">
        {
          data.blog.nodes && data.blog.nodes.map((node) => {
            const { id, postTitle, summary, thumbnail, slug, tags } = node
            const image = getImage(thumbnail && thumbnail.localFile.childImageSharp)
            return (
              <Link key={id} to={`${slug}`}>
                <div className="flex p-10 border-gray-300 shadow">
                  {(image && <div className="group-hover:opacity-75 transition duration-150 ease-in-out">
                    <GatsbyImage image={image} alt={postTitle} />
                  </div>)}
                  <div style={{ display: 'inline-block', marginLeft: '10px' }}>
                    <h3 className="sm:text-lg text-black-600 hover:text-black-500 font-semibold">
                      {postTitle}
                    </h3>
                    <p>{summary}</p>
                    { tags && tags.map(tag => (<span class="inline-block bg-emerald-100 rounded-full text-sm font-semibold text-gray-700 px-3 py-1 mr-2 my-2">#{tag}</span>))}
                  </div>
                </div>
              </Link>
            )
          })
        }
        </div>
      </div>
      {/* <Newsletter /> */}
    </Layout>
  )
}

export default BlogPage

export const query = graphql`
  query BlogQuery {
    blog: allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      nodes {
        id
        category
        postTitle: title
        tags
        summary
        description {
          description
        }
        publishDate
        slug
        thumbnail: heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, PNG, WEBP]
                layout: CONSTRAINED
                width: 100
                placeholder: BLURRED
                quality: 85
                breakpoints: [750, 1080, 1366, 1920]
                transformOptions: { fit: COVER, cropFocus: ATTENTION }
              )
            }
          }
        }
      }
    }
  }
`
